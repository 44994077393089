<template>
	<div class="serviceListHome">
		<!-- 广告ban -->
		<div class="kf_ban"></div>
		<!-- 信息 -->
		<div class="facilitator-center-intro w">
			<div class="center-intro-details">
				<div class="left-intro-details clearfix">
					<div class="left-top-intro left">
						<span class="facilitator-logo">
							<div class="wrap-link-logo"><img alt="" :src="urlImg + xq.img" /></div>
						</span>
						<span class="facilitator-details">
							<h3 class="facilitator-name">{{ xq.title }}</h3>
							<div class="facilitator-text" v-html="xq.describe"><!-- {{ xq.describe }} --></div>
						</span>
					</div>
					<div class="right-like-click right">
						<div class="single-btn" @click="zixun" v-show="show">在线咨询</div>
						<div class="single-btn" @click="fb" v-show="!show">服务发布</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 基本信息 -->
		<div class="w basicInfo">
			<div class="pea-title">
				<div class="one-content" style="padding-top: 0">
					<div class="copy-title">
						<div class="copy-con">
							<span></span>
							<h3>基本信息</h3>
							<span></span>
						</div>
					</div>
				</div>
			</div>
			<div class="basic-table">
				<ul>
					<li class="each-grid-table" v-for="(item, index) in info" :key="index">
						<h3>{{ item.name }}</h3>
						<p>{{ item.text }}</p>
					</li>
				</ul>
			</div>
		</div>
		<!-- 企业介绍 -->
		<div class="com-intros">
			<div class="pea-title">
				<div class="one-content">
					<div class="copy-title">
						<div class="copy-con"><h3>企业介绍</h3></div>
					</div>
				</div>
			</div>
			<div class="w com-p">
				<p v-html="xq.description"><!-- {{ xq.description }} --></p>
			</div>
		</div>
		<!-- 服务产品 -->
		<div class="w servicePro">
			<div class="pea-title">
				<div class="one-content">
					<div class="copy-title">
						<div class="copy-con"><h3>服务产品</h3></div>
					</div>
				</div>
			</div>
			<!-- 服务类型 -->
			<div class="service-tabs">
				<div class="tabs-title">服务类型:</div>
				<ul class="tabs-list">
					<li :class="idx == index ? 'active' : ''" @click="son(it, idx)" class="list-item" v-for="(it, idx) in list" :key="idx">{{ it.name }}</li>
				</ul>
			</div>
			<!-- 综合 -->
			<div class="service-list">
				<div class="tabs">
					<span class="tabs-one" :class="idx == index1 ? 'active1' : ''" @click="Csort(i, idx)" v-for="(i, idx) in sort" :key="idx">{{ i }}</span>
				</div>
				<div class="item-nav">
					<ul v-if="items.length !== 0">
						<li class="items" v-for="(i, idx) in items" :key="idx" @click="Xq(i.id)">
							<img alt="" :src="urlImg + i.img" class="items-image" lazy="loaded" />
							<p class="items-price">{{ i.cost }}元</p>
							<p class="items-info">{{ i.title }}</p>
						</li>
					</ul>
					<div v-else>
						<div class="fruless">
							<div class="fruless-comtain">
								<img src="../../../assets/img/qiyeyun/no.png" alt="暂无内容" class="fruless-image" />
								<div class="fruitless-tip">暂无内容</div>
							</div>
						</div>
					</div>
					<!-- 分页 -->
					<div class="pagination">
						<pagination
							class="pages"
							v-show="total > 0"
							:total="total"
							layout="prev, pager, next"
							:page.sync="listQuery.page"
							:limit.sync="listQuery.limit"
							@pagination="geteProducts"
						/>
					</div>
					<div></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { EmployerXq, Type, eProducts, Info } from '../../../common/js/api';
import { urlImg } from '@/common/js/url';
import Pagination from '@/common/Pagination';

export default {
	name: 'serviceListHome',
	components: { Pagination },
	data() {
		return {
			id: '',
			xq: {
				member_id: ''
			},
			info: [],
			list: [],
			index: 0,
			sort: ['综合', '热门优先', '价格升序'],
			index1: 0,
			items: [],
			urlImg: '',
			total: 0,
			listQuery: {
				limit: 6,
				page: 1
			},
			ItemId: '',
			all: '',
			views: '',
			money: '',
			show: true
		};
	},
	created() {
		this.id = this.$route.query.id;
		this.urlImg = urlImg();
		this.getEmployerXq();
		this.Info();
	},
	methods: {
		Info() {
			Info({
				id: JSON.parse(localStorage.getItem('user')).id
			})
				.then(res => {
					if (res.data.provider) {
						if (res.data.provider.id == this.id) {
							this.show = false;
						} else {
							this.show = true;
						}
					}
				})
				.catch(() => {});
		},
		// 服务商详情
		getEmployerXq() {
			EmployerXq({ id: this.id })
				.then(res => {
					this.xq = res.data;
					// var time = res.data.created_time *1000
					// var myDate = new Date(time );
					// console.log(myDate);
					// res.data.created_time =
					//   myDate.getFullYear() +
					//   "-" +
					//   myDate.getMonth() +
					//   "-" +
					//   myDate.getDate();
					this.info.push(
						{ name: '企业规模', text: res.data.scale },
						{ name: '年营业额', text: res.data.turnover + '万元' },
						{ name: '注册时间', text: res.data.created_time },
						{ name: '注册资本', text: res.data.capitals + '万元' },
						{ name: '法人代表', text: res.data.legal },
						{ name: '所属行业', text: res.data.industry }
					);
					this.getType();
					this.geteProducts();
				})
				.catch(() => {});
		},
		// 服务类型
		getType() {
			Type()
				.then(res => {
					var b = { name: '全部' };
					res.data.unshift(b);
					this.list = res.data;
				})
				.catch(() => {});
		},

		// 服务产品
		geteProducts() {
			eProducts({
				page: this.listQuery.page,
				limits: this.listQuery.limit,
				member_id: this.xq.member_id
			})
				.then(res => {
					this.items = res.data.data;
					this.total = res.data.total;
				})
				.catch(() => {});
		},
		// 在线咨询
		zixun() {
			this.$router.push({
				path: '/chat'
			});
		},
		// 服务发布
		fb() {
			this.$router.push({
				path: '/s_release'
			});
		},
		// 服务类型
		son(item, idx) {
			this.index = idx;
			this.ItemId = item.id;
			this.getindex();
			eProducts({
				type: item.id,
				page: this.listQuery.page,
				limits: this.listQuery.limit,
				member_id: this.xq.member_id,
				all: this.all,
				views: this.views,
				money: this.money
			})
				.then(res => {
					this.items = res.data.data;
					this.total = res.data.total;
				})
				.catch(() => {});
		},
		getindex() {
			if (this.index1 == 0) {
				this.all = 1;
				this.views = '';
				this.money = '';
			} else if (this.index1 == 1) {
				this.all = '';
				this.views = 3;
				this.money = '';
			} else if (this.index1 == 2) {
				this.all = '';
				this.views = '';
				this.money = 2;
			}
		},
		// 排序
		Csort(item, idx) {
			this.index1 = idx;
			if (this.index == -1) {
				this.getindex();
				eProducts({
					page: this.listQuery.page,
					limits: this.listQuery.limit,
					member_id: this.xq.member_id,
					all: this.all,
					views: this.views,
					money: this.money
				})
					.then(res => {
						this.items = res.data.data;
						this.total = res.data.total;
					})
					.catch(() => {});
			} else {
				this.getindex();
				eProducts({
					type: this.ItemId,
					page: this.listQuery.page,
					limits: this.listQuery.limit,
					member_id: this.xq.member_id,
					all: this.all,
					views: this.views,
					money: this.money
				})
					.then(res => {
						this.items = res.data.data;
						this.total = res.data.total;
					})
					.catch(() => {});
			}
		},
		// 进入详情页
		Xq(id) {
			this.$router.push({
				path: '/s_xq',
				query: {
					id: id
				}
			});
		}
	},
	mounted() {}
};
</script>
<style lang="less" scoped>
.serviceListHome {
	background-color: rgba(248, 249, 250, 100);
	padding-bottom: 50px;
	.kf_ban {
		background: url(../../../assets/img/kefuyun/fws.png) no-repeat center;
		background-size: 100% 100%;
		margin-top: 136px;
	}
	//   信息
	.facilitator-center-intro {
		margin: 0 auto;
		position: relative;
		top: -50px;
		.center-intro-details {
			padding: 57px 69px 56px 25px;
			background: #fff;
			max-height: 233px;
			.left-top-intro > span {
				font-size: 14px;
				display: inline-block;
				vertical-align: top;
			}
			.left-top-intro {
				font-size: 0;
				max-width: 920px;
				color: rgba(105, 105, 105, 100);
				.wrap-link-logo {
					width: 120px;
					height: 120px;
					display: block;
					border-radius: 5px;
					overflow: hidden;
				}
				.facilitator-details {
					margin-left: 30px;
					max-width: 727px;
					.facilitator-name {
						font-size: 18px;
						margin-bottom: 20px;
						color: #333;
					}
					.facilitator-text {
						line-height: 25px;
						text-indent: 2em;
						height: 100px;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 4;
						overflow: hidden;
					}
				}
			}
			.right-like-click {
				margin-top: 50px;
				.single-btn {
					cursor: pointer;
					display: block;
					background: #3892eb;
					color: #fff;
					font-size: 16px;
					text-align: center;
					line-height: 40px;
					border-radius: 4px;
					width: 140px;
					transition: 0.3s;
				}
			}
		}
	}
	//   基本信息
	.basic-table {
		background-color: #fff;
		padding: 17px 24px 20px;
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
			.each-grid-table:nth-child(-n + 3) {
				margin-bottom: 34px;
			}
			.each-grid-table {
				flex: 33.3%;
				color: rgba(105, 105, 105, 100);
				font-size: 14px;
				font-family: SourceHanSansSC-regular;
				h3 {
					color: rgba(56, 146, 235, 100);
					font-size: 20px;
					font-family: SourceHanSansSC-bold;
					font-weight: 700;
					margin-bottom: 16px;
					border-left: 6px solid #3892eb;
					padding-left: 14px;
				}
				p {
					padding-left: 14px;
				}
			}
		}
	}
	// 企业介绍
	.com-intros {
		margin-top: 29px;
		padding: 0 0 77px;
		background-color: #3892eb;
		// text-align: center;
		color: #fff;
		font-size: 14px;
		line-height: 25px;
		h3 {
			color: #fff;
		}
		.com-p {
			padding: 0 50px;
			text-indent: 2em;
		}
		.copy-con h3::after,
		.copy-con h3::before {
			content: '';
			background-color: #fff;
		}
	}
	//   服务产品
	.servicePro {
		padding: 12px 23px;
		background-color: #fff;
		border-radius: 5px;
		// 服务类型
		.service-tabs {
			.tabs-title {
				color: rgba(56, 146, 235, 100);
				font-size: 20px;
				font-weight: 700;
				margin: 17px;
				border-left: 6px solid #3892eb;
				padding-left: 14px;
			}
			.tabs-list {
				color: #999;
				.list-item {
					display: inline-block;
					margin: 0 25px;
					margin-bottom: 10px;
					padding: 6px 14px;
					border-radius: 2px;
					cursor: pointer;
				}
				.active {
					background-color: #3892eb;
					color: #fff;
					border-radius: 5px;
				}
			}
		}
		// 综合
		.service-list {
			margin-top: 20px;
			margin-bottom: 20px;
			border-radius: 5px;
			.tabs {
				padding: 19px 34px;
				background-color: #f7f7f7;
				.tabs-one {
					font-size: 18px;
					color: #696969;
					margin-right: 40px;
					cursor: pointer;
				}
				.active1 {
					color: #3892eb;
				}
			}
			.item-nav {
				margin-top: 30px;
				.items:nth-of-type(3n) {
					margin-right: 0;
				}
				.items:hover {
					box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
					border-radius: 5px;
				}
				.items {
					display: inline-block;
					width: 348px;
					margin-right: 50px;
					margin-bottom: 20px;
					transition: 0.3s;
					vertical-align: top;
					text-align: center;
					font-size: 18px;
					cursor: pointer;
					.items-image {
						display: inline-block;
						width: 348px;
						height: 214px;
					}
					.items-price {
						font-weight: 500;
						margin-top: 15px;
						color: #ff0707;
					}
					.items-info {
						margin-top: 15px;
						padding-bottom: 8px;
					}
				}
			}
		}
		.fruless {
			background-color: #fff;
			padding: 0 0 100px;
			text-align: center;
			font-size: 20px;
			.fruless-comtain {
				margin: 0 auto;
				.fruless-image {
					display: inline-block;
					width: 530px;
					height: 404px;
				}
				.fruitless-tip {
					font-size: 20px;
					color: #696969;
					text-align: center;
				}
			}
		}
	}
}
@media screen and (max-width: 1200px) {
	.serviceListHome .kf_ban{
		margin-top: 15rem;
	}
	.w{
		width: 98%;
	}
	.left-intro-details{
		display: flex;
	}
	.serviceListHome .facilitator-center-intro .center-intro-details{
		padding: 3rem 1rem;
	}
	.serviceListHome .facilitator-center-intro .center-intro-details .left-top-intro{
		display: flex;
		padding-right: 1rem;
	}
	.serviceListHome .facilitator-center-intro .center-intro-details .left-top-intro .facilitator-details .facilitator-name{
		font-size: 1.8rem;
	}
	.serviceListHome .facilitator-center-intro .center-intro-details .left-top-intro .facilitator-details .facilitator-text{
		font-size: 1.4rem;
		line-height: 2rem;
		-webkit-line-clamp: 2;
		height: 4rem;
	}
	.serviceListHome .facilitator-center-intro .center-intro-details .right-like-click .single-btn{
		width: 7rem;
		height: 3rem;
		line-height: 3rem;
		font-size: 1.5rem;
	}
	.copy-con h3{
		font-size: 2rem;
	}
	.serviceListHome .basic-table ul .each-grid-table h3{
		font-size: 1.6rem;
	}
	.serviceListHome .basic-table ul .each-grid-table p{
		font-size: 1.4rem;
	}
	.serviceListHome .com-intros .com-p{
		font-size: 1.5rem;
		line-height: 2.4rem;
	}
	.serviceListHome .servicePro .service-tabs .tabs-title{
		font-size: 1.8rem;
	}
	.serviceListHome .servicePro .service-tabs .tabs-list .list-item{
		font-size: 1.4rem;
		padding: 0.7rem 1.5rem;
	}
	.serviceListHome .servicePro .service-list .tabs .tabs-one{
		font-size: 1.6rem;
	}
	.serviceListHome .servicePro .service-list .item-nav ul{
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.serviceListHome .servicePro .service-list .item-nav .items{
		width: 48%;
		margin: 0 0 2rem;
	}
	.serviceListHome .servicePro .service-list .item-nav .items .items-image{
		width: 100%;
		height: 15rem;
	}
	.serviceListHome .servicePro .service-list .item-nav .items .items-price{
		font-size: 1.8rem;
	}
	.serviceListHome .servicePro .service-list .item-nav .items .items-info{
		font-size: 1.4rem;
	}
	/deep/.el-pager li{
		font-size: 1.6rem;
		max-width: 3rem;
		height: auto;
		padding: 0.3rem 0.6rem;
	}
	/deep/i{
		font-size: 1.6rem !important;
		max-width: 3rem;
		height: auto;
		padding: 0.3rem 0.6rem;
	}
	/deep/.btn-prev{
		font-size: 1.6rem !important;
		max-width: 3rem;
		height: auto;
		padding: 0.3rem 0.6rem;
	}
	/deep/.btn-next{
		font-size: 1.6rem !important;
		max-width: 3rem;
		height: auto;
		padding: 0.3rem 0.6rem;
	}
	/deep/.el-icon{
		font-size: 1.6rem !important;
	}
}
</style>
